import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material"
import moment from "moment"
import React, { useEffect } from "react"
import useSocket from "../../context/Socket"
import { DashboardContext } from "../../pages/Dashboard"
import UpdatesList from "./UpdatesList"

export default function LatestUpdates() {
  const { socket } = useSocket()
  const { updates, setUpdates } = React.useContext(DashboardContext)

  React.useEffect(() => {
    socket.on("new_update", (data) => {
      setUpdates((prev) => [data, ...prev])
    })

    return () => {
      socket.off("new_update")
    }
  }, [socket])

  React.useEffect(() => {
    sessionStorage.setItem("updates", JSON.stringify({ updates }))
  }, [updates])

  return (
    <List
      sx={{
        width: "100%",
        maxWidth: "100%",
        bgcolor: "background.paper",
        overflow: "auto",
        height: 381,
      }}
    >
      {updates?.length === 0 ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Typography
            textTransform={"uppercase"}
            color="#ccc"
            variant="h5"
            fontWeight={700}
          >
            no updates available
          </Typography>
        </Box>
      ) : (
        updates.map((item, i) => (
          <>
            <UpdatesList
              item={item}
              key={Math.random() * i + item?.createdAt}
            />
            {i !== updates.length - 1 && (
              <Divider
                key={"divider" + i}
                sx={{ marginLeft: 0 }}
                variant="inset"
                component="li"
              />
            )}
          </>
        ))
      )}
    </List>
  )

  // return (
  //   <Box
  //     sx={{
  //       display: "flex",
  //       justifyContent: "center",
  //       alignItems: "center",
  //       height: 381,
  //     }}
  //   >
  //     Coming Soon
  //   </Box>
  // )
}

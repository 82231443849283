import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material"
import moment from "moment"
import React from "react"
import NoCrashIcon from "@mui/icons-material/NoCrash"
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn"
import GavelIcon from "@mui/icons-material/Gavel"
import { formatPrice } from "../../helppers/formatters"
import { Link } from "react-router-dom"

export default function UpdatesList({ item = item }) {
  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar
          sx={{
            bgcolor:
              item?.type === "Accept"
                ? "green"
                : item?.type === "Counter"
                ? "gold"
                : "red",
            width: 35,
            height: 35,
            color: item?.type === "Counter" ? "black" : "white",
          }}
        >
          {item?.type === "Accept" ? (
            <NoCrashIcon fontSize="small" />
          ) : item?.type === "Counter" ? (
            <GavelIcon fontSize="small" color="black" />
          ) : (
            <DoDisturbOnIcon fontSize="small" />
          )}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Stack
            direction={"row"}
            spacing={2}
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Link
              to={`/leads/item/${item?.id}`}
              style={{
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <Typography variant="body1" fontWeight={600}>
                {item?.user} / {item?.dealership}
              </Typography>
            </Link>
            <Typography variant="body2" fontSize={12}>
              {moment(item?.createdAt).fromNow(false)}
            </Typography>
          </Stack>
        }
        secondary={
          <Stack direction={"row"} spacing={1} alignItems="center">
            <Typography variant="body2">{item?.type}</Typography>
            <Typography variant="body2" fontWeight={500}>
              {item?.title}{" "}
              {item?.type !== "Deny" && `of ${formatPrice(item?.amount)}`}
            </Typography>
          </Stack>
        }
      />
    </ListItem>
  )
}

import React from "react"
import moment from "moment"
import Typography from "@mui/material/Typography"
import {
  Avatar,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from "@mui/material"
import { DashboardContext } from "../../pages/Dashboard"

export default function CountersList({ item, Icon, color = "gold" }) {
  const { selected, setSelected } = React.useContext(DashboardContext)

  return (
    <ListItemButton
      className="db-list"
      alignItems="flex-start"
      sx={{
        bgcolor: item?._id === selected ? "#3c3b6e59" : "inherit",
      }}
      onClick={() => {
        setSelected((prev) => {
          if (prev === item?._id) {
            return null
          } else {
            return item?._id
          }
        })
      }}
    >
      <ListItemAvatar>
        <Avatar
          sx={{
            bgcolor: color,
            width: 35,
            height: 35,
            color: "#424242",
          }}
        >
          <Icon fontSize="small" />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography
            sx={{ display: "inline" }}
            component="h2"
            variant="body2"
            fontWeight={500}
          >
            {item?.title}
          </Typography>
        }
        secondary={
          <React.Fragment>
            <Typography
              sx={{
                display: "inline",
                fontSize: 12,
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
              component="span"
              variant="body2"
              color="text.primary"
            >
              <b>{item?.acceptedBy?.dname}</b> -{" "}
              {moment(item?.acceptedAt).format("MM/DD/YY @ h:mm A")}
            </Typography>
          </React.Fragment>
        }
      />
    </ListItemButton>
  )
}

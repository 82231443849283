import {
  Alert,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material"
import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import axios from "axios"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { LeadsBox } from "../components/boxes"
import FeedBackBox from "../components/global/FeedBackBox"
import { URL } from "../config/apis"
import Pagination from "@mui/material/Pagination"
import { useMemo } from "react"
import LoadingBox from "../components/global/LoadingBox"
import SkeletonCard from "../components/global/SkeletonCard"
import LeadsFilters from "../components/filters/LeadsFilters"
import SimpleModal from "../components/modals/SimpleModal"
import AdvencedFilters from "../components/filters/AdvencedFilters"

const pageSize = 20
const currYear = new Date().getFullYear()

export default function Home() {
  const navigate = useNavigate()
  const cash = JSON.parse(sessionStorage.getItem("leadsPage"))

  const [leads, setLeads] = useState([])
  const [err, setErr] = useState(null)
  const [error, setError] = useState(null)
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [fetching, setFetching] = useState(false)
  const [show, setShow] = useState(false)
  const [open, setOpen] = useState(false)
  const [optin, setOptin] = useState(cash?.optin || "All")

  const [page, setPage] = useState(cash?.page || 1)
  const [market, setMarket] = useState(cash?.market || "All")
  const [agent, setAgent] = useState(cash?.agent || "All")
  const [claimer, setClaimer] = useState(cash?.claimer || "All")
  const [dealership, setDealership] = useState(cash?.dealership || "All")

  const [phoneStatus, setPhoneStatus] = useState(cash?.phoneStatus || "All")

  const [years, setYears] = useState(cash?.years || [2000, currYear + 1])
  const [makes, setMakes] = useState(cash?.makes || [])
  const [models, setModels] = useState(cash?.models || [])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const getLeads = async () => {
    setFetching(true)
    setLoading(true)
    try {
      const res = await axios.patch(
        `${URL}/leads?page=${page}&agent=${agent}&market=${market}&claimer=${claimer}&dealership=${dealership}&phoneStatus=${phoneStatus}&optin=${optin}`,
        { years, makes, models },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      setLeads(res.data?.leads)
      setCount(res.data?.count)
      setOpen(false)
      setLoading(false)
      setFetching(false)
    } catch (error) {
      setLoading(false)
      setFetching(false)
      setErr(error?.response?.data?.message)
    } finally {
      sessionStorage.setItem(
        "leadsPage",
        JSON.stringify({
          years,
          makes,
          models,
          page,
          agent,
          market,
          claimer,
          dealership,
          phoneStatus,
          optin,
        })
      )
    }
  }

  useEffect(() => {
    getLeads()

    sessionStorage.setItem(
      "leadsPage",
      JSON.stringify({
        page,
        agent,
        market,
        claimer,
        dealership,
        phoneStatus,
        optin,
      })
    )
  }, [page, agent, market, claimer, dealership, phoneStatus, optin])

  const totalPages = useMemo(() => {
    return Math.ceil(count / pageSize)
  }, [count, pageSize])

  if (loading) {
    return <SkeletonCard />
  }

  return (
    <>
      {fetching && (
        <div className="alert">
          <Alert icon={<></>} severity="info">
            Loading...
          </Alert>
        </div>
      )}
      <Container maxWidth="100%">
        <LeadsFilters
          market={market}
          setMarket={setMarket}
          agent={agent}
          setAgent={setAgent}
          setPage={setPage}
          count={count}
          claimer={claimer}
          setClaimer={setClaimer}
          dealership={dealership}
          setDealership={setDealership}
          isDealer={true}
          sent
          openFilters={() => setOpen(true)}
          setPhoneStatus={setPhoneStatus}
          phoneStatus={phoneStatus}
          optin={optin}
          setOptin={setOptin}
        />
        <Box>
          {leads?.length === 0 ? (
            <Stack
              sx={{ py: 3, px: 5, minHeight: "500px" }}
              justifyContent={"center"}
              alignItems="center"
              spacing={2}
            >
              <Typography color={"gray"} variant="h5">
                No Leads Found
              </Typography>
              <Stack
                alignItems={"center"}
                justifyContent="center"
                direction={"row"}
                spacing={2}
              >
                <Button onClick={() => navigate(`/new`)} variant="contained">
                  Create new Lead
                </Button>
              </Stack>
            </Stack>
          ) : (
            <Grid container spacing={5}>
              {leads?.map((lead, i) => (
                <Grid key={lead?._id + i} xs={6} md={4} xl={3} item>
                  <LeadsBox
                    lead={lead}
                    setErr={setError}
                    setLeads={setLeads}
                    getLeads={getLeads}
                    setLoading={setIsLoading}
                    setShow={setShow}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
        <br />
        {totalPages > 1 && (
          <Stack
            direction={"row"}
            justifyContent="center"
            sx={{ mt: 2 }}
            spacing={2}
          >
            <Pagination
              page={page}
              onChange={handleChangePage}
              count={totalPages}
              showFirstButton
              showLastButton
            />
          </Stack>
        )}
      </Container>
      <FeedBackBox loading={isLoading} show={show} err={error} />
      <SimpleModal open={open} setOpen={setOpen} isBack>
        <AdvencedFilters
          years={years}
          makes={makes}
          models={models}
          setYears={setYears}
          setMakes={setMakes}
          setModels={setModels}
          setOpen={setOpen}
          getLeads={getLeads}
        />
      </SimpleModal>
    </>
  )
}

import axios from "axios"
import { URL } from "../../config/apis"
import { fromatCFXData } from "../formatCarfaxData"

export const vinDecoder = async (
  vin,
  mileage,
  zip,
  setState,
  setLoading,
  setData,
  setError
) => {
  if (!vin || vin?.length !== 17) {
    setError("VIN not valid")
    return setTimeout(() => {
      setError(null)
    }, 2000)
  }
  setData(null)
  setError(null)
  try {
    setLoading(true)
    const res = await axios.get(
      `${URL}/get-api-data/decode/${vin}?mileage=${mileage}&ZIP=${zip}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    )
    const d = res.data
    if (d?.totalLoss === true) {
      setError("Total Loss")
    }
    setData(res.data)
    setState((prev) => ({
      ...prev,
      year: d?.year,
      make: d?.make,
      model: d?.model,
      trim: d?.trim,
      transmission: {
        short: d?.transmissionDetails,
        full: d?.transmission || d?.transmissionDetails,
      },
      engine: {
        short: d?.engine,
        full: d?.engineDetails || d?.engine,
      },
      type: d?.type,
      extColor: d?.paintColor || d?.paintName,
      intColor: d?.interior,
      msrp: d?.msrp,
      carfax_data: { ...prev?.carfax_data, branded: d?.branded },
    }))
    setLoading(false)
  } catch (error) {
    console.log(error)

    if (error.response) {
      setError(error.response?.data?.message)
    } else {
      setError(error.message)
    }
    setLoading(false)
  }
}
export const wvVinDecoder = async (
  vin,
  setState,
  setLoading,
  setData,
  setError
) => {
  if (!vin || vin?.length !== 17) {
    setError("VIN not valid")
    return setTimeout(() => {
      setError(null)
    }, 2000)
  }
  setData(null)
  setError(null)
  try {
    setLoading(true)
    const res = await axios.get(`${URL}/get-api-data/wv/decode/${vin}`, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    })
    const d = res.data
    if (!d?.make) {
      setError("Something went wrong")
    }
    setData(res.data)
    setState((prev) => ({
      ...prev,
      year: d?.year,
      make: d?.make,
      model: d?.model,
      trim: d?.trim,
      transmission: {
        short: d?.transmission,
        full: d?.transmission,
      },
      engine: {
        short: d?.engine,
        full: d?.engine,
      },
      body: d?.body,
      // type: d?.body,
      extColor: d?.ext_color,
      intColor: d?.int_color,
      msrp: d?.msrp,
      //carfax_data: { ...prev?.carfax_data, branded: d?.branded },
    }))
    setLoading(false)
  } catch (error) {
    console.log(error)

    if (error.response) {
      setError(error.response?.data?.message)
    } else {
      setError(error.message)
    }
    setLoading(false)
  }
}

export const vinWvDecoder = async (vin, setData) => {
  if (!vin || vin?.length !== 17) {
    return
  }
  setData(null)
  try {
    const res = await axios.get(`${URL}/get-api-data/wv/decode/${vin}`, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    })

    setData(res.data)
  } catch (error) {
    console.log(error)
    setData(null)
  }
}

export const bbVinDecoder = async (
  vin,
  mileage,
  zip,
  setState,
  setLoading,
  setData,
  setError
) => {
  if (!vin || vin?.length !== 17) {
    setError("VIN not valid")
    return setTimeout(() => {
      setError(null)
    }, 2000)
  }
  setData(null)
  setError(null)
  try {
    setLoading(true)
    const { data } = await axios.get(
      `${URL}/get-api-data/bb/decode/${vin}?mileage=${mileage}&ZIP=${zip}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    )
    const d = data

    setData(data)
    setState((prev) => ({
      ...prev,
      year: d?.model_year,
      make: d?.make,
      model: d?.model,
      trim: d?.series,
      transmission: {
        short: `${d?.num_gears && d?.num_gears} ${
          d?.transmission && d?.transmission
        }`,
        full: `${d?.num_gears && d?.num_gears} ${
          d?.transmission && d?.transmission
        }`,
      },
      engine: {
        short: d?.engine_description,
        full: d?.engine_description,
      },
      type: d?.style,
      //   extColor: d?.paintColor || d?.paintName,
      //   intColor: d?.interior,
      msrp: d?.msrp,
      //  carfax_data: { ...prev?.carfax_data, branded: d?.branded },
    }))
    setLoading(false)
  } catch (error) {
    console.log(error)

    if (error.response) {
      setError(error.response?.data?.message)
    } else {
      setError(error.message)
    }
    setLoading(false)
  }
}

export const SimpleVinDecoder = async (lead, setData) => {
  if (!lead?.vin || lead?.vin?.length !== 17) {
    return
  }
  setData(null)
  try {
    const res = await axios.get(
      `${URL}/get-api-data/decode/${lead?.vin}?mileage=${lead?.mileage}&ZIP=${lead?.zip}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    )
    setData(res.data)
  } catch (error) {
    console.log(error)
  }
}

export const cargurusDecoder = async (
  vin,
  mileage,
  zip,
  setState,
  setLoading,
  setError
) => {
  if (!vin || vin?.length !== 17) {
    setError("VIN not valid")
    return setTimeout(() => {
      setError(null)
    }, 2000)
  }
  if (!mileage) {
    setError("Mileage is required")
    return setTimeout(() => {
      setError(null)
    }, 2000)
  }
  if (!zip) {
    setError("ZIP is required")
    return setTimeout(() => {
      setError(null)
    }, 2000)
  }
  try {
    setLoading(true)
    const res = await axios.get(
      `${URL}/get-api-data/cargurus/${vin}?mileage=${mileage}&ZIP=${zip}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    )
    const d = res.data
    setState((prev) => ({
      ...prev,
      cgimv: d?.retail ? parseInt(d?.retail) : null,
    }))
    setLoading(false)
  } catch (error) {
    console.log(error)
    if (error.response) {
      setError(error.response?.data?.message)
    } else {
      setError(error.message)
    }
    setLoading(false)
    setTimeout(() => {
      setError(null)
    }, 2000)
  }
}

export const getCarfaxData = async (
  vin,
  setState,
  setLoading,
  setError,
  setData
) => {
  if (!vin || vin?.length !== 17) {
    setError("VIN not valid")
    return setTimeout(() => {
      setError(null)
    }, 2000)
  }
  setError(null)
  setData(null)
  try {
    setLoading(true)
    const res = await axios.get(`${URL}/get-api-data/carfax/${vin}`, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    })
    const d = res.data
    if (!d?.success) {
      setLoading(false)
      setError(d?.error || "Something went wrong")
      return
    }
    const { isClean, tags } = fromatCFXData(d)
    setState((prev) => ({
      ...prev,
      isCleanCfx: isClean,
      carfax: tags,
    }))
    setData(d)
    setLoading(false)
  } catch (error) {
    console.log(error)
    if (error.response) {
      setError(error.response?.data?.message)
    } else {
      setError(error.message)
    }
    setLoading(false)
  }
}

export const getCarfaxDatasimple = async (vin, setData) => {
  if (!vin || vin?.length !== 17) {
    return
  }
  setData(null)
  try {
    const res = await axios.get(`${URL}/get-api-data/carfax/${vin}`, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    })
    const d = res.data
    if (!d?.success) {
      return
    }
    setData(d)
  } catch (error) {
    console.log(error)
  }
}

export const getAutoCheck = async (
  vin,
  setState,
  setLoading,
  setError,
  setData
) => {
  if (!vin || vin?.length !== 17) {
    setError("VIN not valid")
    return setTimeout(() => {
      setError(null)
    }, 2000)
  }
  setError(null)
  setData(null)
  try {
    setLoading(true)
    const res = await axios.get(`${URL}/get-api-data/autocheck/${vin}`, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    })
    const d = res.data
    if (d?.status !== "Succeeded") {
      setLoading(false)
      setError(d?.errorMessage || "Something went wrong")
      return
    }
    setState((prev) => ({
      ...prev,
      isCleanAutocheck: d?.content?.accidentCount === 0,
    }))
    setData(d?.content)
    setLoading(false)
  } catch (error) {
    console.log(error)
    if (error.response) {
      setError(error.response?.data?.message || error.message)
    } else {
      setError(error.message)
    }
    setLoading(false)
  }
}

export const getAutoCheckSimple = async (vin, setData) => {
  if (!vin || vin?.length !== 17) {
    return
  }
  setData(null)
  try {
    const res = await axios.get(`${URL}/get-api-data/autocheck/${vin}`, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    })
    const d = res.data
    if (d?.status !== "Succeeded") {
      return
    }

    setData(d?.content)
  } catch (error) {
    console.log(error)
  }
}

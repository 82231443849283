import React from "react"
import PaperWrrapper from "./PaperWrrapper"
import Grid from "@mui/material/Grid"
import { Chip, Stack } from "@mui/material"

export default function AutoCheckBox({ data }) {
  return (
    <PaperWrrapper title="AutoCheck">
      <Grid item>

      <Stack direction={"row"} gap={2} flexWrap="wrap">
        <Chip
          label={data?.accidentCount === 0 ? "Clean" : "Not Clean"}
          color={data?.accidentCount === 0 ? "success" : "error"}
          sx={{ px: 0.5, py: 0.5 }}
        />
        <Chip
          label={`Accident Reported: ${data?.accidentCount} `}
          color={data?.accidentCount === 0 ? "success" : "error"}
          sx={{ px: 0.5, py: 0.5 }}
        />
        <Chip
          label={`Salvage Title Check: ${
            data?.salvage ? "Salvage" : "No Salvage"
          } `}
          color={data?.salvage ? "error" : "success"}
          sx={{ px: 0.5, py: 0.5 }}
        />
        <Chip
          label={`Last Recorded Odometer: ${data?.lastOdometer?.toLocaleString() || 'N/A'} `}
          sx={{ px: 0.5, py: 0.5 }}
        />
        <Chip
          label={`Last Title State: ${data?.lastTitleState || "N/A"} `}
          sx={{ px: 0.5, py: 0.5 }}
        />
      </Stack>
      </Grid>
    </PaperWrrapper>
  )
}

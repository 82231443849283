import React, { useEffect } from "react"
import SimpleModal from "../../modals/SimpleModal"
import { Alert, Stack } from "@mui/material"
import StepperWrapper from "./StepperWrapper"
import SelectOption from "./SelectOption"
import { URL } from "../../../config/apis"
import axios from "axios"
import AccordinationData from "./AccordinationData"
import OfferBox from "./OfferBox"
import { useNavigate } from "react-router-dom"

export default function EckoparkOffer({ isOpen, setIsOpen, lead }) {
  const navigate = useNavigate()
  const [options, setOptions] = React.useState(null)
  const [offer, setOffer] = React.useState(null)
  const [dealer, setDealer] = React.useState(null)
  const [adQ, setAdQ] = React.useState(new Map())
  const [activeStep, setActiveStep] = React.useState(0)

  const [generating, setGenerating] = React.useState(false)
  const [isSubmited, setIssubmitted] = React.useState(false)
  const [error, setError] = React.useState(null)

  const getOffer = async () => {
    setError(null)
    setGenerating(true)
    const payload = Array.from(adQ.values())
    try {
      const { data } = await axios.put(
        `${URL}/offers-api/v2/adjustment-questions/${options?.appraisalId}`,
        { adjustmentQuestions: payload },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      if (!data.ok) {
        throw new Error("Something went wrong")
      }
      console.log({ data })
      setOffer(data?.offer)
      setIssubmitted(true)
      if (!!data?.offer) {
        setGenerating(false)
        setActiveStep(2)
      } else {
        setTimeout(async () => {
          await getOfferValue()
        }, 2000)
      }
    } catch (error) {
      console.log(error)
      setError(error.response?.data?.message || error?.message)
      setGenerating(false)
    }
  }

  const getOfferValue = async () => {
    setError(null)
    setGenerating(true)
    try {
      const { data } = await axios.get(
        `${URL}/offers-api/v2/offer/${options?.appraisalId}/${lead?._id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      if (!data.ok) {
        throw new Error("Something went wrong")
      }
      console.log({ data })
      setOffer(data?.offer)
      if (!!data?.offer) {
        setGenerating(false)
        setActiveStep(2)
      } else {
        console.log("first")
        setTimeout(() => {
          getOfferValue()
        }, 2000)
      }
    } catch (error) {
      console.log(error)
      setError(error.response?.data?.message || error?.message)
      setGenerating(false)
    }
  }
  const submitOptions = async () => {
    setError(null)
    setGenerating(true)
    try {
      const { data } = await axios.put(
        `${URL}/offers-api/v2/vehicle-details/${options?.appraisalId}`,
        options,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      if (!data.ok) {
        throw new Error("Something went wrong")
      }

      // setOffer(res.data.data?.result)
      const adjustmentQuestionsMap = new Map(
        data?.data?.map((question) => [question.id, question])
      )
      setAdQ(adjustmentQuestionsMap)
      setGenerating(false)

      setActiveStep(1)
    } catch (error) {
      console.log(error)
      setError(error.response?.data?.message || error?.message)
    } finally {
      setGenerating(false)
    }
  }

  const makeOffer = async () => {
    if (
      !offer ||
      isNaN(offer?.offer?.finalOffer) ||
      !dealer ||
      !offer?.offer?.finalOffer
    ) {
      setError("Something went wrong")
      return
    }

    setError(null)
    setGenerating(true)
    try {
      const { data } = await axios.post(
        `${URL}/leads/make-offer/${lead?._id}`,
        {
          dealership: dealer,
          amount: offer?.offer?.finalOffer,
          offer: offer?.offer,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      if (data?.ok) {
        window.location.reload()
      } else {
        throw new Error("Something went wrong")
      }
    } catch (error) {
      console.log(error)
      setGenerating(false)
      setError(error.response?.data?.message || error?.message)
    }
  }

  const handleNext = () => {
    if (activeStep.toString() === "0") {
      submitOptions()
    }
    if (activeStep === 1) {
      getOffer()
    }
    if (activeStep === 2) {
      makeOffer()
    }
  }

  const handleClose = () => {
    setIsOpen(false)
    if (!!offer) {
      navigate("/empty")
      setTimeout(() => {
        navigate(-1)
      }, 0)
    }
  }

  return (
    <SimpleModal
      isBack
      open={isOpen}
      setOpen={setIsOpen}
      setTitle={"Get Offer"}
      maxWidth="100%"
    >
      <Stack spacing={2} sx={{ minWidth: 800, minHeight: 500 }}>
        <StepperWrapper
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          handleNext={handleNext}
          setOpen={handleClose}
          disabled={generating || (activeStep === 2 && offer?.offer?.finalOffer < 0)}
        >
          {error && (
            <Alert sx={{ mb: 1 }} severity="error">
              {error}
            </Alert>
          )}
          {activeStep?.toString() === "0" && (
            <SelectOption
              options={options}
              setOptions={setOptions}
              setErr={setError}
              loading={generating}
              setLoading={setGenerating}
              leadId={lead?._id}
            />
          )}
          {activeStep === 1 && (
            <AccordinationData condition={adQ} setCondition={setAdQ} />
          )}
          {activeStep === 2 && (
            <OfferBox
              theOffer={offer?.offer}
              dealer={dealer}
              setDealer={setDealer}
              lead={lead}
            />
          )}
        </StepperWrapper>
      </Stack>
    </SimpleModal>
  )
}

import React, { useMemo } from "react"
import axios from "axios"
import { URL } from "../../../config/apis"
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material"

export default function SelectOption({
  setOptions,
  options,
  setErr,
  setLoading,
  loading,
  leadId,
}) {
  const getOptions = async () => {
    setErr(null)
    setLoading(true)
    try {
      const { data } = await axios.post(
        `${URL}/offers-api/v2/appraisals/${leadId}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      if (!!data?.details) {
        setOptions(data?.details)
        setLoading(false)
      } else {
        setTimeout(() => {
          getOptions()
        }, 2000)
      }
    } catch (error) {
      console.log(error)
      setErr(error?.response?.data?.message || error.message)
      setLoading(false)
    }
  }

  const handleChange = (key, value, subkey) => {
    setOptions((prev = {}) => {
      let f = []
      if (subkey) {
        f = prev[subkey][key]
      } else {
        f = prev[key]
      }
      f = f?.map((o) => {
        if (o?.id === value) {
          return { ...o, isSelected: true }
        } else {
          return { ...o, isSelected: false }
        }
      })
      if (subkey) {
        return { ...prev, [subkey]: { ...prev[subkey], [key]: f } }
      } else {
        return { ...prev, [key]: f }
      }
    })
  }
  const handleSelectChange = (key, value) => {
    setOptions((prev = {}) => {
      let f = []
      f = prev[key]
      f = f?.map((o) => {
        if (o?.id === value) {
          return { ...o, isSelected: !o?.isSelected }
        } else {
          return { ...o }
        }
      })

      return { ...prev, [key]: f }
    })
  }

  const color = useMemo(() => {
    const ext = options?.exteriorColor || []
    const int = options?.interiorColor || []
    const dt = options?.mechanical?.driveTrains || []
    const eng = options?.mechanical?.engines || []
    const tr = options?.mechanical?.transmissions || []

    const exteriorColor = ext?.find((c) => c.isSelected)?.id
    const interiorColor = int?.find((c) => c.isSelected)?.id
    const driveTrains = dt?.find((c) => c.isSelected)?.id
    const engines = eng?.find((c) => c.isSelected)?.id
    const transmissions = tr?.find((c) => c.isSelected)?.id
    return {
      exteriorColor,
      interiorColor,
      driveTrains,
      engines,
      transmissions,
    }
  }, [options])

  const trim = useMemo(() => {
    const trims = options?.decode[0] || {}
    return trims?.decodeChoices?.find((tr) => tr?.isSelected)
  }, [options?.decode])

  return (
    <>
      {!!options ? (
        <Stack gap={2} width={"100%"}>
          <Grid container columns={9} spacing={2}>
            <Grid sm={1} item>
              <TextField value={options?.year} size="small" />
            </Grid>
            <Grid sm={2} item>
              <TextField value={options?.make} size="small" />
            </Grid>
            <Grid sm={2} item>
              <TextField value={options?.model} size="small" />
            </Grid>
            <Grid item sm={4}>
              <TextField fullWidth value={trim?.name} size="small" />
            </Grid>
            {/* select for trim */}
          </Grid>
          <Divider />

          <Grid width={"100%"} spacing={2} container columns={4}>
            <Grid sm={2} item>
              <FormControl sx={{ bgcolor: "#fff" }} size="small" fullWidth>
                <InputLabel size="small" id={"exteriorColor"}>
                  Exterior Color
                </InputLabel>
                <Select
                  labelId="exteriorColor"
                  // value={JSON.stringify(
                  //   options?.exteriorColor?.find((o) => o?.isSelected)
                  // )}
                  value={color?.exteriorColor}
                  label={"Exterior Color"}
                  // onChange={(e) =>
                  //   handleChange("exteriorColor", JSON.parse(e.target.value))
                  // }
                  onChange={(e) =>
                    handleChange("exteriorColor", e.target.value)
                  }
                  size="small"
                  MenuProps={{ sx: { maxHeight: 450 } }}
                >
                  {!!options?.exteriorColor &&
                    options?.exteriorColor?.map((item, i) => (
                      <MenuItem
                        key={item?.id + i}
                        // value={JSON.stringify(item)}
                        value={item?.id}
                      >
                        {item?.name}
                        {/* {item?.isSelected ? "selected": "not"} */}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid sm={2} item>
              <FormControl sx={{ bgcolor: "#fff" }} size="small" fullWidth>
                <InputLabel size="small" id={"interiorColor"}>
                  Interior Color
                </InputLabel>
                <Select
                  labelId="interiorColor"
                  // value={JSON.stringify(
                  //   options?.interiorColor?.find((o) => o?.isSelected)
                  // )}
                  value={color?.interiorColor}
                  label={"Interior Color"}
                  onChange={(e) =>
                    handleChange("interiorColor", e.target.value)
                  }
                  size="small"
                  MenuProps={{ sx: { maxHeight: 450 } }}
                >
                  {!!options?.interiorColor &&
                    options?.interiorColor?.map((item, i) => (
                      <MenuItem key={item?.id + i} value={item}>
                        {item?.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid sm={2} item>
              <FormControl sx={{ bgcolor: "#fff" }} size="small" fullWidth>
                <InputLabel size="small" id={"driveTrain"}>
                  Drive Train
                </InputLabel>
                <Select
                  labelId="driveTrain"
                  // value={JSON.stringify(
                  //   options?.mechanical?.driveTrains?.find((o) => o?.isSelected)
                  // )}
                  value={color?.driveTrains}
                  label={"Drive Train"}
                  //  onChange={(e) => setDriveTrain(JSON.parse(e.target.value))}
                  size="small"
                  MenuProps={{ sx: { maxHeight: 450 } }}
                  onChange={(e) =>
                    handleChange("driveTrains", e.target.value, "mechanical")
                  }
                >
                  {!!options?.mechanical?.driveTrains &&
                    options?.mechanical?.driveTrains?.map((item, i) => (
                      <MenuItem key={item?.id + i} value={item?.id}>
                        {item?.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid sm={2} item>
              <FormControl sx={{ bgcolor: "#fff" }} size="small" fullWidth>
                <InputLabel size="small" id={"engine"}>
                  Engine
                </InputLabel>
                <Select
                  labelId="engine"
                  // value={JSON.stringify(
                  //   options?.mechanical?.engines?.find((o) => o?.isSelected)
                  // )}
                  value={color?.engines}
                  label={"Engine"}
                  // onChange={(e) => setEngine(JSON.parse(e.target.value))}
                  size="small"
                  MenuProps={{ sx: { maxHeight: 450 } }}
                  onChange={(e) =>
                    handleChange("engines", e.target.value, "mechanical")
                  }
                >
                  {!!options?.mechanical?.engines &&
                    options?.mechanical?.engines?.map((item, i) => (
                      <MenuItem key={item?.id + i} value={item?.id}>
                        {item?.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid sm={2} item>
              <FormControl sx={{ bgcolor: "#fff" }} size="small" fullWidth>
                <InputLabel size="small" id={"transmission"}>
                  Transmission
                </InputLabel>
                <Select
                  labelId="transmission"
                  // value={JSON.stringify(
                  //   options?.mechanical?.transmissions?.find(
                  //     (o) => o?.isSelected
                  //   )
                  // )}
                  value={color?.transmissions}
                  label={"Transmission"}
                  //  onChange={(e) => setTransmission(JSON.parse(e.target.value))}
                  size="small"
                  MenuProps={{ sx: { maxHeight: 450 } }}
                  onChange={(e) =>
                    handleChange("transmissions", e.target.value, "mechanical")
                  }
                >
                  {!!options?.mechanical?.transmissions &&
                    options?.mechanical?.transmissions?.map((item, i) => (
                      <MenuItem key={item?.id + i} value={item?.id}>
                        {item?.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid sm={2} item>
              <FormControl component="fieldset" variant="standard">
                <FormLabel component="legend">Options</FormLabel>
                <FormGroup>
                  {options &&
                    options?.options &&
                    options?.options?.map((item, i) => (
                      <FormControlLabel
                        key={item?.id + i}
                        control={
                          <Checkbox
                            checked={item?.isSelected}
                            onChange={({ target }) =>
                              handleSelectChange("options", target.value)
                            }
                            name={item?.id}
                            value={item?.id}
                          />
                        }
                        label={item?.name}
                      />
                    ))}
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Stack>
      ) : (
        <Stack alignItems={"center"} justifyContent={"center"}>
          <Button
            onClick={getOptions}
            disabled={loading}
            color="primary"
            variant="contained"
          >
            {"Start Appraisal"}
          </Button>
        </Stack>
      )}
    </>
  )
}

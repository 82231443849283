import React from "react"
import { Divider, Grid, List } from "@mui/material"
import AppointmentsBox from "./AppointmentsBox"
import NoCrashIcon from "@mui/icons-material/NoCrash"
import ListItems from "./ListItems"
import { DashboardContext } from "../../pages/Dashboard"
import ListSkeleton from "./ListSkeleton"
import AcceptedList from "./AcceptedList"

export default function LatestAccepted() {
  const { accepted, loading } = React.useContext(DashboardContext)

  const [page, setPage] = React.useState(1)

  const limit = 5
  let startIdx = (page - 1) * limit

  const movePge = (val) => {
    if (val === -1) {
      setPage((prev) => {
        if (prev === 1) {
          return
        }
        return prev + val
      })
    } else {
      setPage((prev) => {
        if (prev === total) {
          return
        }
        return prev + val
      })
    }
  }

  const total = React.useMemo(() => {
    return Math.ceil(accepted?.length / limit)
  }, [accepted, limit])

  const activeArr = React.useMemo(() => {
    startIdx = (page - 1) * limit
    return accepted?.slice(startIdx, startIdx + limit)
  }, [accepted, limit, page])

  return (
    <Grid item sm={4}>
      <AppointmentsBox
        label={"Latest Accepted - " + accepted?.length}
        movePge={movePge}
        page={page}
        total={total}
      >
        <List
          sx={{
            width: "100%",
            maxWidth: "100%",
            bgcolor: "background.paper",
            overflow: "auto",
            maxHeight: 381,
          }}
        >
          {loading ? (
            [...Array(5)].map((_, i) => <ListSkeleton key={"ske-" + i} />)
          ) : (
            <>
              {activeArr.map((item, i) => (
                <>
                  <AcceptedList
                    i={i}
                    item={item}
                    key={"list-" + i + item?._id}
                    Icon={NoCrashIcon}
                  />
                  {i !== activeArr?.length - 1 && (
                    <Divider
                      key={item?._id + "divider" + i}
                      sx={{ marginLeft: 0 }}
                      variant="inset"
                      component="li"
                    />
                  )}
                </>
              ))}
            </>
          )}
        </List>
      </AppointmentsBox>
    </Grid>
  )
}

import { Box, Grid } from "@mui/material"
import axios from "axios"
import React from "react"
import { useEffect } from "react"
import { useState } from "react"
import { useParams } from "react-router-dom"
import ActionsGroupBtn from "../components/actions/ActionsGroupBtn"
import Breadcrumb from "../components/actions/Breadcrumb"
import LeftSide from "../components/leadPage/LeftSide"
import RightSide from "../components/leadPage/RightSide"
import { URL } from "../config/apis"
import {
  getAutoCheckSimple,
  getCarfaxDatasimple,
  SimpleVinDecoder,
} from "../helppers/apiCalls/decoders"
import Lightbox from "react-18-image-lightbox"
import "react-18-image-lightbox/style.css"

export default function SingleLeadpage() {
  const { id } = useParams()
  const [lead, setLead] = useState(null)
  const [err, setErr] = useState(null)
  const [loading, setLoading] = useState(true)
  const [DealerActivity, setDealerActivity] = useState([])
  const [activity, setActivity] = useState([])
  const [counters, setCounters] = useState([])
  const [appointmnets, setAppointments] = useState([])
  const [data, setData] = useState(null)
  // const [cfx, setCfx] = useState(null)
  const [acheck, setAcheck] = useState(null)
  const [isOpen, setIsOpen] = React.useState(false)
  const [photoIndex, setPhotoIndex] = React.useState(0)

  const getLeadById = async () => {
    setLoading(true)
    setLead(null)
    setData(null)
    // setCfx(null)
    setAcheck(null)
    setActivity([])
    setDealerActivity([])
    try {
      const res = await axios.get(`${URL}/leads/item/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      if (res.data.vin) {
        setLead(res.data)
        // await SimpleVinDecoder(res.data, setData)
        // await getCarfaxDatasimple(res.data?.vin, setCfx)
        await getAutoCheckSimple(res.data?.vin, setAcheck)
        const [actRes, dres, cRes, calRes] = await Promise.all([
          axios.get(`${URL}/activity/lead/${id}`, {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }),
          axios.get(`${URL}/activity/d-activity/${id}`, {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }),
          axios.get(`${URL}/counters/lead/${id}`, {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }),
          axios.get(`${URL}/calendar/lead/${id}`, {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }),
        ])
        setActivity(actRes.data)
        setDealerActivity(dres.data)
        setCounters(cRes.data)
        setAppointments(calRes.data)
      } else {
        setErr("Lead not found")
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setErr(error?.response?.data?.message)
    }
  }

  useEffect(() => {
    getLeadById()
  }, [id])

  const images = React.useMemo(() => {
    if (!lead) return []
    return [lead?.f_image?.url, ...lead?.images?.map((x) => x?.url)]
  }, [lead])

  if (loading) {
    return <div>loading...</div>
  }

  if (!loading && !lead && err) {
    return <div>{err}</div>
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Breadcrumb title={lead?.title} />
        <ActionsGroupBtn
          lead={lead}
          setLead={setLead}
          setActivity={setActivity}
        />
      </Box>
      <Grid container spacing={2}>
        <Grid item sm={9}>
          <LeftSide
            lead={lead}
            activity={activity}
            autocheck={acheck}
            data={data}
            setActivity={setActivity}
            setIsOpen={setIsOpen}
            setLead={setLead}
          />
        </Grid>
        <Grid item sm={3}>
          <RightSide
            lead={lead}
            dActivity={DealerActivity}
            setActivity={setActivity}
            setLead={setLead}
            setDealerActivity={setDealerActivity}
            counters={counters}
            setCounters={setCounters}
            appointmnets={appointmnets}
            setAppointments={setAppointments}
          />
        </Grid>
      </Grid>
      {isOpen && images && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images?.length]}
          prevSrc={images[(photoIndex + images?.length - 1) % images?.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (prev) => (prev + images?.length - 1) % images?.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((prev) => (prev + 1) % images?.length)
          }
          style={{ zIndex: 999999 }}
        />
      )}
    </>
  )
}
